<template>
  <div
    v-if="computedDataParams"
    style="
      max-width: 100%;
      width: 100%;
      height: 100%;
      margin-right: auto;
      margin-left: auto;
      height: 100%;
      background-color: #ffffff;
    "
  >
    <section
      class="pdf-item"
      style="padding: 28px 32px; flex-direction: row; display: flex; justify-content: space-between; flex-wrap: wrap"
    >
      <div>
        <div style="display: flex; margin-bottom: 24px; align-items: center">
          <img
            :src="appLogo"
            height="50px"
            width="50px"
            alt="logo"
            style="margin: 12px; background-position: center center"
          />
          <span
            style="color: #333333; font-family: 'Orbitron'; font-weight: 700; font-size: 1.25rem; line-height: 1.75rem"
          >
            FANATY
          </span>
        </div>
      </div>

      <div>
        <p
          style="
            padding: 0;
            margin: 0;
            display: flex;
            color: #333333;
            margin-bottom: 16px;
            align-items: center;
            font-weight: 500;
            font-size: 1.25rem;
            line-height: 1.75rem;
            font-family: 'Inter', sans-serif;
          "
        >
          {{ t('products.current_inventory') }}
        </p>
        <p
          style="
            padding: 0;
            margin: 0;
            color: #333333;
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 1.5;
            letter-spacing: 0.0071428571em;
            padding: 0;
            margin: 0;
            font-family: 'Inter', sans-serif;
            margin-bottom: 8px;
            padding: 0;
            margin: 0;
          "
        >
          <span
            style="
              color: #333333;
              font-size: 0.875rem;
              font-weight: 400;
              line-height: 1.5;
              letter-spacing: 0.0071428571em;
            "
          >{{ t('products.inventory_date') }}
          </span>
          <span
            style="
              color: #333333;
              font-size: 0.875rem;
              font-weight: 600;
              line-height: 1.5;
              letter-spacing: 0.0071428571em;
            "
          >{{ computedNow }}</span>
        </p>
      </div>

      <div></div>
    </section>

    <section
      class="pdf-item"
      style="padding: 0px 28px 16px; flex-direction: row; display: flex; justify-content: center; flex-wrap: wrap"
    >
      <h2 style="color: #333333; font-family: 'Inter', sans-serif; font-weight: 500; font-size: 1.25rem; line-height: 1.75rem;">
        {{ t('products.product_list').toUpperCase() }}
      </h2>
    </section>

    <div v-if="computedDataParams.length">
      <section
        v-for="(mapItem, i) in computedMapTransactions"
        :key="i"
        class="pdf-item"
        :style="styleBreak(i)"
        style="
          background-color: #ffffff;
          color: #333333;
          line-height: 1.5;
          max-width: 100%;
          border-radius: 5px;
          padding: 0px 32px;
        "
      >
        <div
          v-if="mapItem.length"
          style="overflow-x: auto; overflow-y: hidden"
        >
          <table style="width: 100%; border-spacing: 0; font-family: 'Inter', sans-serif">
            <thead style="display: table-header-group; vertical-align: middle; border-color: inherit">
              <tr style="display: table-row; vertical-align: inherit; border-color: inherit">
                <th
                  style="
                    border-bottom: thin solid #e7e3fc24;
                    text-align: left;
                    background-color: #333333 !important;
                    user-select: none;
                    font-size: 0.7rem;
                    height: 3.375rem;
                    padding: 0 12px;
                    vertical-align: inherit;
                    transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
                    color: #ffffff !important;
                    display: table-cell;
                  "
                >
                  ID
                </th>
                <th
                  style="
                    border-bottom: thin solid #e7e3fc24;
                    text-align: left;
                    background-color: #333333 !important;
                    user-select: none;
                    font-size: 0.7rem;
                    height: 3.375rem;
                    padding: 0 12px;
                    vertical-align: inherit;
                    transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
                    color: #ffffff !important;
                    display: table-cell;
                  "
                >
                  {{ t('products.product').toUpperCase() }}
                </th>
                <th
                  style="
                    border-bottom: thin solid #e7e3fc24;
                    text-align: center;
                    background-color: #333333 !important;
                    user-select: none;
                    font-size: 0.7rem;
                    height: 3.375rem;
                    padding: 0 12px;
                    vertical-align: inherit;
                    transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
                    color: #ffffff !important;
                    display: table-cell;
                  "
                >
                  {{ t('products.system').toUpperCase() }}
                </th>
                <th
                  style="
                    border-bottom: thin solid #e7e3fc24;
                    text-align: center;
                    background-color: #333333 !important;
                    user-select: none;
                    font-size: 0.7rem;
                    height: 3.375rem;
                    padding: 0 12px;
                    vertical-align: inherit;
                    transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
                    color: #ffffff !important;
                    display: table-cell;
                  "
                >
                  {{ t('products.new_stock').toUpperCase() }}
                </th>
              </tr>
            </thead>

            <tbody
              style="
                display: table-row-group;
                vertical-align: middle;
                border-color: inherit;
                border-bottom: thin solid #a199cc !important;
              "
            >
              <tr
                v-for="item in mapItem"
                :key="item.id"
                style="display: table-row; vertical-align: inherit; border-color: inherit"
              >
                <td
                  style="
                    border-bottom: thin solid #a199cc !important;
                    font-size: 0.8rem;
                    height: 3.125rem;
                    padding: 0 12px;
                    transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
                    color: #333333;
                    white-space: nowrap;
                    display: table-cell;
                    vertical-align: inherit;
                  "
                >
                  #{{ item.id }}
                </td>
                <td
                  style="
                    border-bottom: thin solid #a199cc !important;
                    font-size: 0.8rem;
                    height: auto;
                    min-height: 3.125rem;
                    padding: 2px 12px;
                    transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
                    color: #333333;
                    white-space: break-spaces;
                    display: flex;
                    align-items: center;
                    vertical-align: inherit;
                  "
                >
                  <img
                    v-if="item.image"
                    :src="item.image"
                    :alt="item.name"
                    width="40"
                    height="40"
                    style="margin-right: 12px;"
                  >
                  {{ item.name }}
                </td>
                <td
                  style="
                    border-bottom: thin solid #a199cc !important;
                    font-size: 0.8rem;
                    height: 3.125rem;
                    padding: 0 12px;
                    transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
                    color: #333333;
                    white-space: nowrap;
                    display: table-cell;
                    vertical-align: inherit;
                    text-align: center;
                  "
                >
                  {{ item.prev_stock }}
                </td>
                <td
                  style="
                    border-bottom: thin solid #a199cc !important;
                    font-size: 0.8rem;
                    height: 3.125rem;
                    padding: 0 12px;
                    transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
                    color: #333333;
                    white-space: nowrap;
                    display: table-cell;
                    vertical-align: inherit;
                    text-align: center;
                  "
                ></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          v-if="mapItem.length >= 16 || (i === 0 && computedMapTransactions.length > 1)"
          class="html2pdf__page-break"
        />
      </section>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { ref, computed } from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import { formatCurrency } from '@core/utils'
import themeConfig from '@themeConfig'
import moment from 'moment'

export default {
  props: {
    dataParams: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const { t } = useUtils()

    const now = ref(moment(new Date()))
    const computedNow = computed(() => now.value.format('MM-DD-YYYY, hh:mm'))

    const computedDataParams = computed(() => props.dataParams)

    const resolveColor = status => {
      if (status === 'S' || status === 'N') return '#56CA00'
      if (status === 'R' || status === 'Y') return '#FFB400'

      return '#FF4C51'
    }

    const getSubtotals = trans => {
      const subtotal = trans.reduce((acc, cur) => acc + cur.subtotal, 0)

      return formatCurrency(subtotal)
    }

    const getDiscounts = trans => {
      const discount = trans.reduce((acc, cur) => acc + cur.discount, 0)

      return discount !== 0 ? `(${formatCurrency(discount)})` : '($0.00)'
    }

    const getComission = trans => {
      const comission = trans.reduce((acc, cur) => acc + cur.comission, 0)

      return comission !== 0 ? `(${formatCurrency(comission)})` : '($0.00)'
    }

    const getTax = trans => {
      const tax = trans.reduce((acc, cur) => acc + cur.tax, 0)

      return tax !== 0 ? `${formatCurrency(tax)}` : '$0.00'
    }

    const getRefunded = trans => {
      const refunded = trans.filter(e => e.refunded === 'Y').reduce((acc, cur) => acc + cur.subtotal, 0)

      return refunded !== 0 ? `${formatCurrency(refunded)}` : '($0.00)'
    }

    const mapTransactions = () => {
      const arr = computedDataParams.value

      if (arr.length <= 7) return [arr]

      const sections = Math.ceil((arr.length - 10) / 18)
      const arrMap = []
      arrMap.push(arr.slice(0, 10))
      const auxArr = arr.slice(10)
      for (let i = 0; i < sections; i += 1) {
        if (i === 0) arrMap.push(auxArr.slice(0, 18))
        else arrMap.push(auxArr.slice((18 * i), ((18 * i) + 18)))
      }

      return arrMap
    }
    const computedMapTransactions = computed(() => mapTransactions())

    const styleBreak = i => {
      if (i > 0) return { 'margin-top': '72px' }

      return {}
    }

    return {
      computedNow,

      // computed
      computedDataParams,
      computedMapTransactions,

      // methods
      resolveColor,
      formatCurrency,
      getSubtotals,
      getDiscounts,
      getComission,
      getTax,
      getRefunded,
      styleBreak,

      // i18n
      t,

      appLogo: themeConfig.app.logo,
    }
  },
}
</script>
